import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
// reactstrap components
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';

import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	getVideoCallSummary,
    clearVideoCallHistoryData
} from '../../../actions';
import VideoCallHistory from './VideoCallHistory';
import { DialogContent } from '@mui/material';


class VideoCallHistoryPopup extends Component {

    state = {
        search: "",
        showSearch: false,
        callHistoryData: [],
    };

    handleCloseCallHistory = () => {
        this.props.clearVideoCallHistoryData()
        this.props.handleClose();
    }

    handelSearch = (e) => {
        console.log(e);
        this.setState({ search: e.target.value });
    }

    handelCloseSearch = () => {
        this.setState({ search: "", showSearch: false });
    }

    render() {
        const { search, showSearch } = this.state;
        let {openCallHistory, loading, videoCallSummaryDetail: callHistoryLogs, hasMoreData, isMobileView} = this.props
        const callHistoryData = search && callHistoryLogs && callHistoryLogs.length > 0 ? callHistoryLogs.filter((li) => (li.groupName && li.groupName.toLowerCase().includes(search.toLowerCase())) || (li.members && Object.values(li.members)[0].name && Object.values(li.members)[0].name.toLowerCase().includes(search.toLowerCase()))) : callHistoryLogs;
        console.log("hasMoreData :", hasMoreData, isMobileView)
        return (
            <Dialog
                open={openCallHistory}
                fullScreen={isMobileView}
                maxWidth="xs"
                aria-labelledby="responsive-dialog-title"
                className={`${this.props.popupThemeClass} notices-list-panel start-workflow-popup`}
            >

                <DialogTitle>
                    <div className="d-flex align-items-center template-header justify-content-between all-clients-for-grooup p-2 pr-3">
                        <div className='d-flex align-items-center'>
                            <IconButton className="" onClick={this.handleCloseCallHistory.bind(this)} ><ArrowBackIcon /></IconButton>
                            <h4 className='m-0'>Call History</h4>
                        </div>

                        <div className="d-flex align-items-center">
                            {showSearch ?
                                <form className="mr-3 w-100">
                                    <TextField id="standard-search"
                                        onChange={(e) => this.handelSearch(e)}
                                        type="search"
                                        value={search}
                                        placeholder="Search Contacts"
                                        autoFocus={true}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CloseIcon color="primary" onClick={() => this.handelCloseSearch()} style={{ cursor: "pointer" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </form>
                                :
                                <IconButton className=" mx-4 " data-testid="search-button-on-add-contacts" onClick={() => this.setState({ showSearch: true })}><SearchIcon /></IconButton>
                            }
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className='start-workflow-container p-2' id="scrollable1">
                    <VideoCallHistory
                        loading={loading}
                        videoCallHistoryData={callHistoryData}
                        hasMoreData={hasMoreData}
                        infiniteScrollId="scrollable1"
                    />
                </DialogContent>
            </Dialog>
        );
    }
}
const mapStateToProps = ({videoCallHistory, settings }) => {
	
	const {videoCallSummaryDetail, loading, missedVCallClientCount, pageCount, hasMoreData} = videoCallHistory
    const {isMobileView} = settings
	return { videoCallSummaryDetail, loading, missedVCallClientCount, pageCount, hasMoreData, isMobileView};
}

export default connect(mapStateToProps, {
	getVideoCallSummary,
	clearVideoCallHistoryData
})(VideoCallHistoryPopup);

