import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
// reactstrap components
// Material UI Components
import DialogContent from '@mui/material/DialogContent';
import { connect } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import {getFormatedCallTime} from "../../../helpers/helpers"
import LifeSherpaLoading from '../../LifeSherpaLoading';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import InfiniteScroll from "react-infinite-scroll-component";
import {
	getVideoCallSummary,
    clearVideoCallHistoryData
} from '../../../actions';
import CircularProgress from '@mui/material/CircularProgress';
import { doVideoCall } from '../../../helpers/helpers';
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';
import CallReceivedRoundedIcon from '@mui/icons-material/CallReceivedRounded';
import CallMissedRoundedIcon from '@mui/icons-material/CallMissedRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';

class VideoCallHistory extends Component {

    componentDidMount() {
        this.props.getVideoCallSummary()
    }

    componentWillUnmount() {
        this.props.clearVideoCallHistoryData()
    }

    handleContactClick = (callHistoryItem) => {
        const callerId = callHistoryItem.members && Object.keys(callHistoryItem.members)[0]
        const callItem = {...callHistoryItem, userId: callerId}
        doVideoCall(callItem)
    }

    handleloadmore = () => {
		let {pageCount} = this.props;
		this.props.getVideoCallSummary(pageCount);
	}

    render() {
        let {loading, videoCallSummaryDetail: callHistoryLogs, hasMoreData, videoCallHistoryData, infiniteScrollId} = this.props
        const callHistoryData =  videoCallHistoryData || callHistoryLogs;
        console.log("hasMoreData :", hasMoreData)
        return (
            <div className="p-0">
                <LifeSherpaLoading loading={loading && (!callHistoryData)} />
                {callHistoryData && callHistoryData.length > 0 ?
                <InfiniteScroll
                    dataLength={callHistoryData && callHistoryData.length}
                    next={() => this.handleloadmore()}
                    hasMore={hasMoreData}
                    style={{ overflow: "none" }}
                    scrollableTarget={infiniteScrollId}
                >
                        <ul className="list-unstyled" data-testid="all-call-history-list">
                        {callHistoryData && callHistoryData.length > 0 && callHistoryData.map((callHistoryItem, index) => {
                           return callHistoryItem.members ? ( <li className={`d-flex list-item list-card align-items-center justify-content-between p-2 mb-2 cps border-bottom alternate-color`} key={index + 'user'} onClick={(e) => this.handleContactClick(callHistoryItem)} data-testid={`call-history-list-item-${index}`}>
                                <div className="d-flex align-items-center" >
                                    {callHistoryItem && callHistoryItem.group ?
                                        <Avatar key={index} alt="Remy Sharp" className={callHistoryItem?.status === "missed" ? "align-self-center bg-danger" : "align-self-center user-later-avatar"} ><GroupRoundedIcon /></Avatar>
                                        : callHistoryItem && callHistoryItem.members && Object.values(callHistoryItem.members) && Object.values(callHistoryItem.members)[0].profileImageURL && Object.values(callHistoryItem.members)[0].profileImageURL !== '' ?
                                            <img src={Object.values(callHistoryItem.members)[0].profileImageURL} className="rounded-circle align-self-center" width="40" height="40" />
                                        : <Avatar className={callHistoryItem?.status === "missed" ? "align-self-center user-later-avatar bg-danger" : "align-self-center user-later-avatar"}>{Object.values(callHistoryItem.members)[0].name.charAt(0)}</Avatar>
                                    }
                                    <div className='ml-2'>
                                        <h4 className={callHistoryItem?.status === "missed" ? "mb-0 text-danger": "mb-0"}>{callHistoryItem && callHistoryItem.group ? callHistoryItem.groupName : Object.values(callHistoryItem.members)[0].name}</h4>
                                        <div className='d-flex align-items-center'>
                                            {callHistoryItem.status === 'missed' ? 
                                                <CallMissedRoundedIcon className='mr-1 text-danger'/>
                                            : callHistoryItem.status === 'incoming' ?
                                                <CallReceivedRoundedIcon className='mr-1'/>
                                            :
                                                <CallMadeRoundedIcon className='mr-1'/>
                                            }
                                            <p className={callHistoryItem?.status === "missed" ? "mb-0 text-danger": "mb-0"}>{callHistoryItem.status}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className={callHistoryItem?.status === "missed" ? "text-danger": ""}>{ getFormatedCallTime(callHistoryItem?.endTime || callHistoryItem?.startTime)}</div>
                                    <IconButton className={callHistoryItem?.status === "missed" ? 'ml-2 bg-danger' : 'ml-2 user-later-avatar'}>
                                    {callHistoryItem?.status === "missed" ?
                                        <VideocamRoundedIcon className="text-white" width="24px" height="24px" />
                                        : <VideocamRoundedIcon width="24px" height="24px"/>
                                    }
                                    </IconButton>
                                </div>
                            </li>) : <></>
                        })
                        }
                    </ul>
                    {callHistoryData && callHistoryData.length > 0 && loading &&
                        <div className="d-flex justify-content-center my-3">
                            <CircularProgress color='progress' />
                        </div>
                    }
                </InfiniteScroll>
                :  !loading && <div className='d-flex list-item list-card align-items-center justify-content-between p-2 mb-2 cps border-bottom alternate-color'>No Conatct found</div>
            }
            </div>
        );
    }
}
const mapStateToProps = ({videoCallHistory }) => {
	
	const {videoCallSummaryDetail, loading, missedVCallClientCount, pageCount, hasMoreData} = videoCallHistory
	return { videoCallSummaryDetail, loading, missedVCallClientCount, pageCount, hasMoreData};
}

export default connect(mapStateToProps, {
	getVideoCallSummary,
	clearVideoCallHistoryData
})(VideoCallHistory);

